import React from 'react'
import { api } from '../Constants';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
function Addsection() {



    const [data, setData] = useState([]);

    useEffect(() => {
        const apiUrl = api + 'ads_list';
        axios.post(apiUrl)
            .then((response) => {
                setData(response.data.data);
            })
            .catch((error) => {
                if (error.response) {
                    console.log('Server Error:', error.response.data);
                } else if (error.request) {
                    console.log('No Response Received');
                } else {
                    console.log('Error:', error.message);
                }
            });
    }, []);


    return (
        <div>
            <div class="banner-statistics-area section-padding add-sec">
                <div class="container">
                    <div class="row row-20 mtn-20">
                        {data !== undefined && data.length > 0 ? data.map((item) => {
                            return (
                                <div class="col-md-6  col-sm-12 " key={item.ads_id}>
                                    <figure class="banner-statistics mt-20">
                                        <Link to={item.link}>
                                            <img src={item.image} alt="product banner" />
                                        </Link>
                                        <div class="banner-content text-right">
                                            <h5 class="banner-text1">{item.title}</h5>
                                            <h2 class="banner-text2">{item.main_title}</h2>
                                        </div>
                                    </figure>
                                </div>
                            )
                        }) : null}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Addsection
