import React from 'react'

import Image1 from '../../assets/new-images/about.jpg'
function Aboutus() {
    return (
        <div>
            <section className="about-us section-padding aboutus-new">
                <div className="container">
                    <div className="row align-items-center">
                    <h2 className="about-title about-tittle-mobile-view">About Us</h2>
                        <div className="col-lg-5">
                            <div className="about-thumb">
                                <img src={Image1} alt="about thumb" />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="about-content">
                                <h2 className="about-title about-tittle-desc-view">About Us</h2>
                                <p> <b> SRI VISHALI ARTHIKA BANGLES </b> has consistently upheld a reputation as a venerable and specialized innovator in gold bangles manufacturing since its establishment in 1992. Our journey has seen a transformation from the traditional craftsmanship of handmade bangles to the precision and efficiency of CNC Fusion technology. This transition is a testament to our unwavering commitment to technological evolution, ensuring the paramount quality and finish that our clients demand.</p>
                                <p>Our dedication to technological advancements is a daily practice, and it is this relentless pursuit of excellence that sets our products apart. The hallmark of our bangles is their unique design and unmatched durability, thanks to the incorporation of cutting-edge imported mechanisms in our production processes.</p>
                                <p>Over the course of three decades, we have cultivated exceptional and enduring relationships with a network of esteemed distributors, dealers, and retailers within Tamil Nadu. This long-standing rapport underscores our steadfast presence in the market and our unwavering commitment to delivering exceptional bangles.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Aboutus