

const api = 'https://arthikajewels.com/admin/api/'

const Productcategory = "https://arthikajewels.com/admin/api/search_productcategory";

const Categorylisturl = "https://arthikajewels.com/admin/api/category_list";

export { api, Productcategory, Categorylisturl }

