
import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios';
import { Categorylisturl } from '../Constants';
import { Helmet } from 'react-helmet';
import Modal from 'react-bootstrap/Modal';
import { api } from '../Constants';
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from 'react-router-dom';
import Productdetail from '../Productdetail/Productdetail';
function Ourproduct() {
  const [productdetails, setProductDetails] = useState([]);
  const [categorylist, setCategorylist] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);


  const handleShow = (productCode) => {
    setShow(true);
    setFormData({ ...formData, product_code: productCode });
  }

  var subject = "testing"

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };
  const handleChange1 = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };
  const handleChange3 = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };
  const handleChange4 = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    city: '',
    message: '',
    subject,
    // Add other form fields as needed
  });
  const handleClick = () => {
    if (!formData.name == "" && !formData.mobile == "" && !formData.email == "" && !formData.city == "" && !formData.message == "") {
      fetch(api + 'add_enquirydetails', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          changeOrigin: true,
        },
        body: JSON.stringify(formData),
      })
        .then(response => {
          if (response.status === 200) {

            alert("send message succesfully");
            handleClose();

          }
          else (
            alert("some error is occured")
          )
        })
    }
  }





  useEffect(() => {

    axios.post(Categorylisturl)
      .then((response) => {

        setCategorylist(response.data.data);
        if (response.data.data !== undefined && response.data.data.length > 0) {
          setSelectedCategoryId(response.data.data[0].category_slug);
        }
      })
      .catch((error) => {
        console.error('Error fetching category list:', error);
      });
  }, []);

  useEffect(() => {
    if (selectedCategoryId !== null) {

      axios.post(api + "search_productcategory", {
        category: selectedCategoryId,
      })
        .then((res) => {

          setProductDetails(res.data.data);
        })
        .catch((error) => {
          console.error('Error fetching product details:', error);
        });
    }
  }, [selectedCategoryId]);



  const handleCategoryClick = (category_slug) => {
    setSelectedCategoryId(category_slug);
  };


  function onChange(value) {
    console.log("Captcha value:", value);
  }


  return (
    <div>
      <div>
        <Helmet>Category ||Arthika jewels</Helmet>
      </div>
      <section className="feature-product section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-center">
                <h2 className="title">Our products</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <Tabs>
              <div className="product-tab-menu">
                <ul className="nav justify-content-center">
                  <TabList className="nav justify-content-center">
                    {categorylist !== undefined && categorylist.length > 0 ? categorylist.slice(0, 4).map((category) => (
                      <Tab key={category.category_slug}>
                        <li>
                          <button onClick={() => handleCategoryClick(category.category_slug)} data-bs-toggle="tab">
                            {category.name}
                          </button>
                        </li>
                      </Tab>
                    )) : null}
                  </TabList>
                </ul>
              </div>
              <div className="col-12">
                <div>
                  {categorylist !== undefined && categorylist.length > 0 ? categorylist.map((category) => (
                    <TabPanel key={category.category_slug}>
                      {category.category_slug === selectedCategoryId && (
                        <div className="row">
                          {productdetails !== undefined && productdetails.length > 0 ? productdetails.map((item) => (
                            <div key={item.product_id} className="col-6 col-sm-6  col-md-4  col-lg-3">
                              <div className="product-item">
                                <figure className="product-thumb">
                                  <Link to={`/product-details/${item.prd_slug_name}`}>
                                    <img className="pri-img our-products-home" src={item.product_image} alt="product" />
                                  </Link>
                                  <div className="cart-hover">
                                    <button className="btn btn-cart" onClick={() => {
                                      handleShow(item.product_code)
                                    }}>Enquiry Now</button>
                                  </div>
                                </figure>
                                <div className="product-caption text-center">
                                  <h6 className="product-name">
                                    <Link to={`/product-details/${item.prd_slug_name}`}>{item.name}</Link>
                                  </h6>
                                  <div className="product-identity">
                                    <p className="manufacturer-name">Product Code:  <span className='price-regular'>{item.product_code}</span></p>
                                  </div>
                                  <div className="price-box">
                                    <span className="price-old">Weight :</span>
                                    <span className=" price-regular"> {item.weight} g</span>
                                  </div>
                                  <div className="product-identity mt-2">
                                    <p className="manufacturer-name">Qty :  <span className='price-regular'>{item.quantity}</span></p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )) : null}
                        </div>
                      )}
                    </TabPanel>
                  )) : null}

                  <Modal show={show} onHide={handleClose} className='modal fade entry-popup'>

                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel"> <span>For Enquiry Enter Your Details</span></h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ border: 'none' }} onClick={() => {
                        setShow(false)
                      }}>
                        <span className='contactclosebutton' aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <Modal.Body>
                      <form method="POST" action='javascript:void(0)'>
                        <div className="row mb3 g-3">
                          <div className="col-lg-12 col-md-12 mb20">
                            <input type="text" className="form-control" placeholder="Your Name"
                              id='name'
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                              onKeyPress={(event) => {
                                if (!/[a-z,A-Z]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              required

                            ></input>
                          </div>
                          <div className="col-lg-12 col-md-12 mb20">
                            <input type="text" className="form-control" placeholder="Phone Number" id="mobile"
                              name="mobile"
                              value={formData.mobile}
                              onChange={handleChange1}
                              maxLength="10"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              required
                            ></input>
                          </div>
                          <div className="col-lg-12 col-md-12 mb20">
                            <input type="email" className="form-control" placeholder="Email Address" id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange2}
                              required
                            ></input>
                          </div>
                          <div className="col-lg-12 col-md-12 mb20">
                            <input type="text" className="form-control" placeholder="Enter Your City" id="city"
                              name="city"
                              value={formData.city}
                              onChange={handleChange4}
                              required
                            ></input>
                          </div>
                          <div class="col-lg-12 col-sm-12 mb20"><textarea type="Message" class="form-control" placeholder="Message" id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange3}
                            required

                          ></textarea><small id="messageerror" class="error"></small></div>
                          <div>
                            <div className="col-lg-12 col-md-12 mb20">
                              <ReCAPTCHA
                                sitekey={"6LdGiY0oAAAAAH9EQi94D43RkIXkRWpc1x99AUTv"}
                                Secret Key={"6LdGiY0oAAAAALltd2vMsg0i0HeJ9J33rYDM7pnP"}
                                className="g-recaptcha my-3"
                                onChange={onChange}
                              />
                            </div>
                            <Modal.Footer className='validationpopup-button-sec'>
                              <button className="main-btn mr2" download="" onClick={handleClick}>Submit</button>
                              <button type="close" className="main-btn ml2 closebtn" data-dismiss="modal" onClick={() => {
                                setShow(false)
                              }}>close</button>
                            </Modal.Footer></div>
                        </div>
                      </form>
                    </Modal.Body>

                  </Modal>
                </div>


              </div>
            </Tabs>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Ourproduct;
