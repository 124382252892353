import React from 'react'
import Image1 from '../../assets/new-images/logo-3.png'
import { useState, useEffect } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { NavLink, Link } from 'react-router-dom';
import { api } from '../Constants';
import axios from 'axios'
function Header() {

    const [categorylist, setCategorylist] = useState([]);
    useEffect(() => {
        axios.post(api + "category_list")

            .then((response) => {
                setCategorylist(response.data.data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    const [stickyClass, setStickyClass] = useState('')

    const [isOpen, setIsOpen] = useState(false);

    const [menuOpenone, setMenuOpen] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const Mobilemenu = () => {
        setMenuOpen(!menuOpenone);
    };

    useEffect(() => {
        window.addEventListener('scroll', stickNavbar)
        return () => window.removeEventListener('scroll', stickNavbar)
    }, [])

    const stickNavbar = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY
            windowHeight > 70 ? setStickyClass('sticky is-sticky') : setStickyClass('')
        }
    }

    const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);

    const toggleSubMenu = () => {
        setIsSubMenuVisible(!isSubMenuVisible);
    };


    return (
        <div>

            <header className="header-area header-wide">
                <div className="main-header d-none d-lg-block">
                    <div className="header-top bdr-bottom">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-10 col-md-10 ">
                                    <ul className="adress-flex">
                                        <li><i className="pe-7s-mail"></i> <a href="mailto:orders@arthikajewels.com">orders@arthikajewels.com </a></li>
                                        <li><i className="pe-7s-call"></i> <a href="tel: +919003720691"> +91 9003720691</a></li>
                                        <li><i className="pe-7s-home"></i><a href="https://www.google.com/maps/place/Sri+Vishali+Art+Bangles/@11.0059286,76.9575313,17z/data=!4m14!1m7!3m6!1s0x3ba8598d1499ebd3:0x3bd9ab36699ab9e5!2sSri+Vishali+Art+Bangles!8m2!3d11.0059286!4d76.9575313!16s%2Fg%2F11fkt4p67f!3m5!1s0x3ba8598d1499ebd3:0x3bd9ab36699ab9e5!8m2!3d11.0059286!4d76.9575313!16s%2Fg%2F11fkt4p67f?hl=en-US&entry=ttu" target='_blank'>
                                            Door no: 7/8, Devangapet street - 3, Coimbatore - 641001.
                                        </a></li>

                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className={`header-main-area ${stickyClass}`}>
                        <div className="container">
                            <div className="row align-items-center position-relative">

                                <div className="col-lg-2">
                                    <div className="logo">
                                        <NavLink to="/">
                                            <img src={Image1} alt="Brand-Logo" />

                                        </NavLink>
                                    </div>
                                </div>

                                <div className="col-lg-6 position-static">
                                    <div className="main-menu-area">
                                        <div className="main-menu">

                                            <nav className="desktop-menu">
                                                <ul>
                                                    <li><Link to="/">Home</Link></li>
                                                    <li><Link to="/About-Us">About Us</Link></li>
                                                    <li><a href="javascript:void(0);">Collections <i className="fa fa-angle-down"></i></a>
                                                        <ul className="dropdown">
                                                            {categorylist !== undefined && categorylist.length > 0
                                                                ? categorylist.map((ca) => {
                                                                    return (
                                                                        <li key={ca.category_id}> <Link to={`/category/${ca.category_slug}`}>{ca.name}</Link></li>
                                                                    )
                                                                }) : null}
                                                        </ul>

                                                    </li>
                                                    <li><Link to="contact-us">Contact us</Link></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="header-right d-flex align-items-center  justify-content-lg-end">
                                        <div className="header-configure-area">
                                            <div className="header-top-settings header-top-socialicon">
                                                <ul className="nav align-items-center justify-content-end">
                                                    <li className="curreny-wrap">
                                                        <a href="https://www.facebook.com/arthikajewel" target='_blank'><i className="fa fa-facebook"></i></a>
                                                    </li>

                                                    <li className="curreny-wrap">
                                                        <a href="https://www.instagram.com/arthikajewels/" target='_blank'><i className="fa fa-instagram"></i></a>
                                                    </li>

                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`mobile-header d-lg-none d-md-block ${stickyClass}`}>
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <div className="mobile-main-header">
                                    <div className="mobile-logo">
                                        <Link to="/">
                                            <img src={Image1} alt="Brand Logo" />
                                        </Link>
                                    </div>
                                    <div className="mobile-menu-toggler">
                                        <button onClick={handleShow} className="mobile-menu-btn">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Offcanvas show={show} onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title><img src={Image1} alt="logo" /></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="mobile-navigation">
                            <nav>
                                <ul className="mobile-menu">
                                    <li className="menu-item-has-children"><Link onClick={handleClose} to='/'>Home</Link>

                                    </li>
                                    <li className="menu-item-has-children"><Link onClick={handleClose} to='/About-Us'>About Us</Link>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <span onClick={toggleSubMenu} className="menu-expand"><i></i></span>
                                        <a href="javascript:void(0);">Collections</a>

                                        {isSubMenuVisible && (
                                            <div>
                                                <ul className="dropdown">
                                                    {categorylist.map((ca) => {
                                                        return (
                                                            <li className="menu-item-has-children" key={ca.category_slug}> <Link to={`/category/${ca.category_slug}`} onClick={handleClose}>{ca.name}</Link></li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        )}
                                    </li>
                                    <li><Link onClick={handleClose} to='/contact-us'>Contact us</Link></li>
                                </ul>
                            </nav>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>

            </header>
        </div>
    )
}

export default Header