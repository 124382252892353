import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';
import { Productcategory } from '../Constants';
import Modal from 'react-bootstrap/Modal';
import ReCAPTCHA from "react-google-recaptcha";
import { api } from '../Constants';
import { Link } from 'react-router-dom';
function Category() {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);

  const params = useParams();
  const categoryval = params.slug;
  var subject = "testing";

  const productsPerPage = 9;
  const [pageClicked, setPageClicked] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [productdetails, setProductDetails] = useState([]);
  const [heading, setHeading] = useState([]);


  const [filterKeyword, setFilterKeyword] = useState("");
  const startIndex = (currentPage - 1) * productsPerPage;
  const endIndex = startIndex + productsPerPage;

  const filteredProducts = productdetails.filter((product) => {
    return product.keyword.toLowerCase().includes(filterKeyword.toLowerCase());
  });

  const currentPageProducts = filteredProducts.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setPageClicked(true);
    setCurrentPage(newPage);
  };

  useEffect(() => {
    if (pageClicked) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setPageClicked(false);
    }
  }, [pageClicked]);

  useEffect(() => {
    axios.post(Productcategory, {
      category: categoryval,
    })
      .then((res) => {
        setProductDetails(res.data.data);
        setHeading(res.data.data[0])
      })
      .catch((error) => {
      });
  }, [params]);
  const noResults = filteredProducts.length === 0;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = (productCode) => {
    setShow(true);
    setFormData({ ...formData, product_code: productCode });
  }
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };
  const handleChange1 = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };
  const handleChange3 = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };
  const handleChange4 = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    city: '',
    message: '',
    subject,
    // Add other form fields as needed
  });
  const handleClick = () => {
    if (!formData.name == "" && !formData.mobile == "" && !formData.email == "" && !formData.city == "" && !formData.message == "") {
      fetch(api + 'add_enquirydetails', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          changeOrigin: true,
        },
        body: JSON.stringify(formData),
      })
        .then(response => {
          if (response.status === 200) {
            alert("send message succesfully");
            handleClose();
          }
          else (
            alert("some error is occured")

          )
        })
    }
  }
  function onChange(value) {
    console.log("Captcha value:", value);
  }


  useEffect(() => {
    setCurrentPage(1);
  }, [filterKeyword]);

  useEffect(() => {
    setCurrentPage(1);
  }, [categoryval]);

  return (
    <div>
      <div className="shop-main-wrapper section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="shop-product-wrapper">
                <div className="shop-top-bar">
                  <div className="row align-items-center">
                    <div className="col-lg-5 col-md-6   order-md-1">
                      <div className="top-bar-left">
                        <div className="product-amount">
                          <h4>{heading.category_name}</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-6  order-md-2">
                      <div className="top-bar-right">
                        <div className="product-short">
                          <input
                            type="text"
                            placeholder="Filter by Keyword"
                            value={filterKeyword}
                            onChange={(e) => setFilterKeyword(e.target.value)} />
                          <button type="submit"><i className="fa fa-search"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="shop-product-wrap grid-view row mbn-30" id="myUL">
                  {noResults ? (
                    <div className="col-md-12 text-center">
                      <p>No Results Found</p>
                    </div>
                  ) : (
                    currentPageProducts.map((detailsone) => {
                      return (
                        <div className="col-md-4 col-sm-6" key={detailsone.product_id}>
                          <div className="product-item">
                            <figure className="product-thumb">
                              <Link to={`/product-details/${detailsone.prd_slug_name}`}>
                                <img className="pri-img our-products-home" src={detailsone.product_image} alt="product" />
                              </Link>
                              <div className="cart-hover">
                                <button className="btn btn-cart" onClick={() => {
                                  handleShow(detailsone.product_code)
                                }}>Enquiry Now</button>
                              </div>
                            </figure>
                            <div className="product-caption text-center">
                              <h6 className="product-name">
                                <Link to={`/product-details/${detailsone.prd_slug_name}`}>{detailsone.name}</Link>
                              </h6>

                              <div className="product-identity">
                                <p className="manufacturer-name">Product Code : <span className='price-regular'>{detailsone.product_code}</span></p>
                              </div>
                              <div className="price-box">
                                <span className=" price-old">Weight :</span>
                                <span className="price-regular">{detailsone.weight} g</span>
                              </div>
                              <div className="price-box mt-2">
                                <span className=" price-old">Qty :</span>
                                <span className="price-regular">{detailsone.quantity}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>

                <div className="paginatoin-area text-center">
                  <ul className="pagination-box">
                    <li>
                      <button
                        className="previous"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}>
                        <i className="pe-7s-angle-left"></i>
                      </button>
                    </li>
                    {[...Array(Math.ceil(filteredProducts.length / productsPerPage)).keys()].map((page) => (
                      <li key={page} className={currentPage === page + 1 ? "active" : ""}>
                        <button onClick={() => handlePageChange(page + 1)}>{page + 1}</button>
                      </li>
                    ))}
                    <li>
                      <button
                        className="next"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === Math.ceil(filteredProducts.length / productsPerPage)}
                      >
                        <i className="pe-7s-angle-right"></i>
                      </button>
                    </li>
                  </ul>
                </div>
                <Modal show={show} onHide={handleClose} className='modal fade entry-popup'>

                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel"> <span>For Enquiry Enter Your Details</span></h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ border: 'none' }} onClick={() => {
                      setShow(false)
                    }}>
                      <span className='contactclosebutton' aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <Modal.Body>
                    <form method="POST" action='javascript:void(0)'>
                      <div className="row mb3 g-3">
                        <div className="col-lg-12 col-md-12 mb20">
                          <input type="text" className="form-control" placeholder="Your Name"
                            id='name'
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            onKeyPress={(event) => {
                              if (!/[a-z,A-Z]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            required
                          ></input>
                        </div>
                        <div className="col-lg-12 col-md-12 mb20">
                          <input type="text" className="form-control" placeholder="Phone Number" id="mobile"
                            name="mobile"
                            value={formData.mobile}
                            onChange={handleChange1}
                            maxLength="10"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            required
                          ></input>
                        </div>
                        <div className="col-lg-12 col-md-12 mb20">
                          <input type="email" className="form-control" placeholder="Email Address" id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange2}
                            required
                          ></input>
                        </div>

                        <div className="col-lg-12 col-md-12 mb20">
                          <input type="text" className="form-control" placeholder="Enter Your City" id="city"
                            name="city"
                            value={formData.city}
                            onChange={handleChange4}
                            required
                          ></input>
                        </div>

                        <div class="col-lg-12 col-sm-12 mb20"><textarea type="Message" class="form-control" placeholder="Message" id="message"
                          name="message"
                          value={formData.message}
                          onChange={handleChange3}
                          required
                        ></textarea><small id="messageerror" class="error"></small></div>


                        <div>
                          <div className="col-lg-12 col-md-12 mb20">
                            <ReCAPTCHA
                              sitekey={"6LdGiY0oAAAAAH9EQi94D43RkIXkRWpc1x99AUTv"}
                              Secret Key={"6LdGiY0oAAAAALltd2vMsg0i0HeJ9J33rYDM7pnP"}
                              className="g-recaptcha my-3"
                              onChange={onChange}
                            />
                          </div>
                          <Modal.Footer className='validationpopup-button-sec'>
                            <button className="main-btn mr2" download="" onClick={handleClick}>Submit</button>
                            <button type="close" className="main-btn ml2 closebtn" data-dismiss="modal" onClick={() => {
                              setShow(false)
                            }}>close</button>
                          </Modal.Footer></div>
                      </div>
                    </form>
                  </Modal.Body>

                </Modal>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Category; 