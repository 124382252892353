import React from 'react';
import Homebanner from './Homebanner';
import Addsection from './Addsection';
import Featureproduct from './Featureproduct';
import Category from './Category';
import Ourproduct from './Ourproduct';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
function Homeparent() {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);
  return (
    <div>
      <div>
        <Helmet>Home</Helmet>
      </div>
      <Homebanner />
      <Addsection />
      <Featureproduct />
      <Category />
      <Ourproduct />
    </div>
  )
}

export default Homeparent
