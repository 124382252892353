import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { api } from '../Constants';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import ReCAPTCHA from "react-google-recaptcha";
import Customarrows from './Customarrows';
function Featureproduct() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  var subject = "testing"

  const handleShow = (productCode) => {
    setShow(true);
    setFormData({ ...formData, product_code: productCode });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleChange3 = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleChange4 = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const productcode_details = (vals) => {
    localStorage.removeItem('item_code');
    localStorage.setItem("item_code", vals);
  };
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    city: '',
    message: '',
    subject,
    product_code: '',
  });
  const handleClick = () => {
    if (!formData.name == "" && !formData.mobile == "" && !formData.email == "" && !formData.city == "" && !formData.message == "") {
      fetch(api + 'add_enquirydetails', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          changeOrigin: true,
        },
        body: JSON.stringify(formData),
      })
        .then(response => {
          if (response.status === 200) {
            alert("send message succesfully");
            handleClose();
          }
          else (
            alert("some error is occured")
          )
        })
    }
  }
  var settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: <Customarrows className="slick-prev" isNext={false} />,
    nextArrow: <Customarrows className="slick-next" isNext={true} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false

        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const [data, setData] = useState([]);
  useEffect(() => {
    const apiUrl = api + 'select_featuredproduct';
    axios.post(apiUrl)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        if (error.response) {
          console.log('Server Error:', error.response.data);
        } else if (error.request) {
          console.log('No Response Received');
        } else {
          console.log('Error:', error.message);
        }
      });
  }, []);
  function onChange(value) {
    console.log("Captcha value:", value);

}
  return (
    <div>
      <section class="product-area section-padding">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="section-title text-center">
                <h2 class="title">feature products</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="product-container">
                <Slider {...settings} className='slick-arrow-style slick-arrow-style_hero'>
                  {data !== undefined && data.length > 0 ? data.map((item) => (
                    <div class="product-item" >
                      <figure class="product-thumb">
                        <Link to={`/product-details/${item.slug}`}>
                          <img className="pri-img" src={item.product_image} alt="Product" />
                        </Link>
                        <div class="cart-hover">
                          <button class="btn btn-cart" onClick={() => {
                            handleShow(item.product_code)
                          }}>Enquiry Now</button>
                        </div>
                      </figure>
                      <div class="product-caption text-center">
                        <h6 class="product-name">
                          <Link to={`/product-details/${item.slug}`}>{item.name}</Link>
                        </h6>
                        <div class="product-identity">
                          <p class="manufacturer-name">Product Code : <span className='price-regular'>{item.product_code} </span></p>
                        </div>

                        <div class="price-box">
                          <span class="price-old">Weight :</span>
                          <span class=" price-regular">{item.weight} g</span>
                        </div>

                        <div class="product-identity mt-2">
                          <p class="manufacturer-name">Qty : <span className='price-regular'>{item.quantity}</span></p>
                        </div>

                      </div>
                    </div>
                  ))
                    : null}


                </Slider>

                <Modal show={show} onHide={handleClose} className='modal fade entry-popup'>

                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel"> <span>For Enquiry Enter Your Details</span></h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ border: 'none' }} onClick={() => {
                      setShow(false)
                    }}>
                      <span className='contactclosebutton' aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <Modal.Body>
                    <form method="POST" action='javascript:void(0)'>
                      <div className="row mb3 g-3">
                        <div className="col-lg-12 col-md-12 mb20">
                          <input type="text" className="form-control" placeholder="Your Name"
                            id='name'
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            onKeyPress={(event) => {
                              if (!/[a-z,A-Z]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            required

                          ></input>
                        </div>
                        <div className="col-lg-12 col-md-12 mb20">
                          <input type="text" className="form-control" placeholder="Phone Number" id="mobile"
                            name="mobile"
                            value={formData.mobile}
                            onChange={handleChange1}
                            maxLength="10"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            required
                          ></input>
                        </div>
                        <div className="col-lg-12 col-md-12 mb20">
                          <input type="email" className="form-control" placeholder="Email Address" id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange2}
                            required
                          ></input>
                        </div>
                        <div className="col-lg-12 col-md-12 mb20">
                          <input type="text" className="form-control" placeholder="Enter Your City" id="city"
                            name="city"
                            value={formData.city}
                            onChange={handleChange4}
                            required
                          ></input>
                        </div>

                        <div class="col-lg-12 col-sm-12 mb20"><textarea type="Message" class="form-control" placeholder="Message" id="message"
                          name="message"
                          value={formData.message}
                          onChange={handleChange3}
                          required
                        ></textarea><small id="messageerror" class="error"></small></div>
                        <div>
                          <div className="col-lg-12 col-md-12 mb20">
                            <ReCAPTCHA
                              sitekey={"6LdGiY0oAAAAAH9EQi94D43RkIXkRWpc1x99AUTv"}
                              Secret Key={"6LdGiY0oAAAAALltd2vMsg0i0HeJ9J33rYDM7pnP"}
                              className="g-recaptcha my-3"
                              onChange={onChange}
                            />
                          </div>
                          <input type="hidden" id="product_code" name="product_code" value={formData.product_code} readOnly />
                          <Modal.Footer className='validationpopup-button-sec'>
                            <button className="main-btn mr2" download="" onClick={handleClick}>Submit</button>
                            <button type="close" className="main-btn ml2 closebtn" data-dismiss="modal" onClick={() => {
                              setShow(false)
                            }}>close</button>
                          </Modal.Footer></div>
                      </div>
                    </form>
                  </Modal.Body>

                </Modal>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default Featureproduct
