import React from 'react'
import Aboutus from './About-us'
import Whychooseus from './Why-choose-us'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
function Aboutmain() {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);
  return (
    <div>
      <div>
        <Helmet>
          About Us || Arthika jewels
        </Helmet>
      </div>
      <Aboutus />
      <Whychooseus />
    </div>
  )
}

export default Aboutmain
