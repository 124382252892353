import Carousel from 'react-bootstrap/Carousel';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { api } from '../Constants';
import { Link } from 'react-router-dom';


function Homebanner() {
    const [data, setData] = useState([]);
    useEffect(() => {
        const apiUrl = api + 'banner_list';
        axios.post(apiUrl)
            .then((response) => {
                setData(response.data.data);
                if (response.data.data !== undefined && response.data.data.length > 0) {
                }
            })
            .catch((error) => {
                if (error.response) {
                    console.log('Server Error:', error.response.data);
                } else if (error.request) {
                    console.log('No Response Received');
                } else {
                    console.log('Error:', error.message);
                }
            });
    }, []);
    return (
        <div>
            <Carousel fade className='home-banner' interval={2000}>
                {data !== undefined && data.length > 0 ? data.map((item, index) => {
                    const isOdd = index % 2 !== 0;
                    const alignmentClass = isOdd ? 'home-banner-right' : ' home-banner-left';
                    return (
                        <Carousel.Item className='banner-h' key={item.banner_id} >
                            <img src={item.image}></img>
                            <Carousel.Caption className={`sst ${alignmentClass}`} >
                                <div className="hero-single-slide hero-overlay">
                                    <div className="banner-odd">
                                        <div className="hero-slider-content slide-1">
                                            <h2 className="slide-title">{item.title}<span>Collection</span></h2>
                                            <h4 className="slide-desc"><span dangerouslySetInnerHTML={{ __html: item.description }}></span></h4>
                                            <Link to={item.link} className="btn btn-hero">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    )
                }) : null}
            </Carousel>
        </div>
    )
}
export default Homebanner
