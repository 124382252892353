import React from 'react'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
function Customarrows({ className, onClick, text, isNext }) {
    return (
        <div className={className} onClick={onClick}>
            {isNext ? <FaArrowRight /> : <FaArrowLeft />} {text}
        </div>
    )
}

export default Customarrows