import React from 'react'
import Slider from "react-slick";
import { useState, useEffect } from 'react';
import axios from "axios";
import { NavLink, Link } from 'react-router-dom';
import { api } from '../Constants';
import Customarrows from './Customarrows';
function Category() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <Customarrows className="slick-prev" isNext={false} />,
    nextArrow: <Customarrows className="slick-next" isNext={true} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [categorylist, setCategorylist] = useState([]);
  useEffect(() => {
    axios.post(api + "category_list")
      .then((response) => {
        setCategorylist(response.data.data);
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  }, []);
  return (
    <section className="product-banner-statistics">
      <div className="container-fluid">
        <div className="row">
          <Slider {...settings} className="product-banner-carousel">
            {categorylist !== undefined && categorylist.length > 0 ? categorylist.map((item) => {
              return (
                <div className="banner-slide-item">
                  <figure className="banner-statistics">
                    <Link to={`/category/${item.category_slug}`}>
                      <a href="javascript:void(0);">
                        <img src={item.image} alt="product banner" />
                      </a>
                      <div className="banner-content banner-content_style2">
                        <h5 className="banner-text3"><a href="javascript:void(0);">{item.name}</a></h5>
                      </div>
                    </Link>
                  </figure>
                </div>
              )
            }) : null}
          </Slider>
        </div>
      </div>
    </section>
  )
}
export default Category;