import React from 'react'

function Whychooseus() {
    return (
        <div>
            <div className="choosing-area section-padding pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title text-center ">
                                <h2 className="title">Our Collections</h2>
                                <h6 className='my-3'>Our collections will involve all kinds of varities for users from varied culture for varied tastes, each craft will have an utmost uniqueness with finest finish,  We design jewellery from the consumer’s mindset to deliver astronishing products to our clients.</h6>
                            </div>
                        </div>
                    </div>
                    {/*   <div className="row mbn-30">
                    <div className="col-lg-4 col-md-4">
                        <div className="single-choose-item text-center mb-30">
                            <i className="fa fa-globe"></i>
                            <h4>free shipping</h4>
                            <p>Amadea Shop is a very slick and clean e-commerce template with endless possibilities.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="single-choose-item text-center mb-30">
                            <i className="fa fa-plane"></i>
                            <h4>fast delivery</h4>
                            <p>Amadea Shop is a very slick and clean e-commerce template with endless possibilities.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <div className="single-choose-item text-center mb-30">
                            <i className="fa fa-comments"></i>
                            <h4>customers support</h4>
                            <p>Amadea Shop is a very slick and clean e-commerce template with endless possibilities.</p>
                        </div>
                    </div>
                </div>
                */}
                </div>
            </div>
        </div>
    )
}

export default Whychooseus